import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "file-preview" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "file-name" }
const _hoisted_6 = {
  key: 1,
  class: "file-name"
}
const _hoisted_7 = { class: "file-size" }
const _hoisted_8 = {
  key: 0,
  class: "file-error text-danger"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "file-action-buttons" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProgressRing = _resolveComponent("ProgressRing")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['file-icon', {'rtl-icon-style': $options.isRTL}])
    }, [
      ($options.is_image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $data.src,
            alt: $props.file.name
          }, null, 8 /* PROPS */, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "fallback",
            innerHTML: _ctx.frappe.utils.icon('file', 'md')
          }, null, 8 /* PROPS */, _hoisted_3))
    ], 2 /* CLASS */),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        ($props.file.doc)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "flex",
              href: $props.file.doc.file_url,
              target: "_blank"
            }, [
              _createElementVNode("span", _hoisted_5, _toDisplayString($props.file.name | _ctx.file_name), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_4))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.file.name | _ctx.file_name), 1 /* TEXT */))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_7, _toDisplayString($props.file.file_obj.size | _ctx.file_size), 1 /* TEXT */)
      ]),
      _createCommentVNode(" <div class=\"flex config-area\">\n\t\t\t\t<label v-if=\"is_optimizable\" class=\"frappe-checkbox\"><input type=\"checkbox\" :checked=\"optimize\" @change=\"$emit('toggle_optimize')\">Optimize</label>\n\t\t\t\t<label class=\"frappe-checkbox\"><input type=\"checkbox\" :checked=\"file.private\" @change=\"$emit('toggle_private')\">Private</label>\n\t\t\t</div> "),
      _createElementVNode("div", null, [
        ($props.file.error_message)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($props.file.error_message), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['file-actions', {'rtl-style': $options.isRTL}])
    }, [
      _withDirectives(_createVNode(_component_ProgressRing, {
        primary: "var(--primary-color)",
        secondary: "var(--gray-200)",
        radius: 24,
        progress: $options.progress,
        stroke: 3
      }, null, 8 /* PROPS */, ["progress"]), [
        [_vShow, $props.file.uploading && !$options.uploaded && !$props.file.failed]
      ]),
      ($options.uploaded)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.frappe.utils.icon('solid-success', 'lg')
          }, null, 8 /* PROPS */, _hoisted_9))
        : _createCommentVNode("v-if", true),
      ($props.file.failed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: _ctx.frappe.utils.icon('solid-error', 'lg')
          }, null, 8 /* PROPS */, _hoisted_10))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_11, [
        _createCommentVNode(" <button v-if=\"is_cropable\" class=\"btn btn-crop muted\" @click=\"$emit('toggle_image_cropper')\" v-html=\"frappe.utils.icon('crop', 'md')\"></button> "),
        (!$options.uploaded && !$props.file.uploading && !$props.file.failed)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn muted",
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('remove'))),
              innerHTML: _ctx.frappe.utils.icon('delete', 'md')
            }, null, 8 /* PROPS */, _hoisted_12))
          : _createCommentVNode("v-if", true)
      ])
    ], 2 /* CLASS */)
  ]))
}